import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategyService } from './content/pages/services/custom-preloading-strategy.service';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./content/pages/pages.module').then(m => m.PagesModule)
	},
	{
		path: '**',
		redirectTo: '404',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { useHash: true, 	
		preloadingStrategy: CustomPreloadingStrategyService})
	],
	exports: [RouterModule]
})

export class AppRoutingModule { }
