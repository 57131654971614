// USA
export const locale = {
    lang: 'fn', 
    data:{
        "ckeywords": {
            "customer": "Asiakas",
            "scustomer": "Valitse asiakas",
            "amount": "Summa (€/kk)",
            "data": "Tiedonsiirto (Mt/kk)",
            "t5users": "Top 5-käyttäjät",
            "inumber": "Laskun numero",
            "inumber1": "Laskun numero:",
            "ucount": "Käyttäjien lukumäärä :",
            "ucount1": "Liittymät ja laitteet",
            "mucount":"Mobiililiittymät",
            "invoiceamt":"Laskun summa",
            "export": "Vie tiedostoon",
            "import": "Tuonti",
            "operator": "Operaattori",
            "cplan": "Nykyinen liittymä",
            "cuamount": "Nykykustannus (€/kk)",
            "opamount": "Uusi kustannus (€/kk)",
            "samount": "Säästö (€/kk)",
            "bccenter": "Kustannuspaikoittain",
            "ccenter": "Kustannuspaikka",
            "usummaries": "Optimoitava kokonaiskäyttö",
            "plantype": "Liittymätyypit",
            "cperson": "Yhteyshenkilö", 
            "eid": "Sähköpostiosoite",
            "country": "Maa",
            "action": "Muokkaa",
            "anew": "Lisää uusi",
            "fmamandatory": "*pakollinen tieto",
            "eiirequired": "Sähköpostiosoite on pakollinen tieto",
            "save": "Tallenna",
            "cancel": "Peruuta",
            "iactive": "Aktiivinen",
            "soperator": "Valitse operaattori",
            "cgname": "Kustannusryhmä",
            "ename": "Syötä Kustannusryhmän nimi",
            "ctype": "Kustannuskategorian tyyppi",
            "tbskipped": "Ohitetaan optimoinnissa",
            "no": "Ei",
            "pgroup": "Liittymätyyppi-ryhmä",
            "yes": "Kyllä",
            "search": "Etsi",
            "uname": "Käyttäjä",
            "pnumber": "Puhelinnumero",
            "reset":"Nollaa",
            "celement": "Kustannuslaji",
            "euros":"Euroa",
            "reports": "Raportit",
            "eorganization":"Koko organisaatio",
            "masters": "Hallintapaneeli",
            "sccenter": "Valitse kustannuspaikka",
            "samonth": "Valitse laskutusjakso",
            "epnumber": "Syötä puhelinnumero",
            "pnirequired": "Puhelinnumero on pakollinen tieto",
            "pniinvalid":"Puhelinnumero on virheellinen",
            "eiiinvalid":"Sähköpostiosoite on virheellinen",
            "ccirequired":"Hintamaa vaaditaan",
            "edit": "Muokkaa",
            "cconfirm": "Haluatko varmasti peruuttaa?",
            "required": "{{name}} vaaditaan",
            "invalid": "{{name}} ei kelpaa!",
            "delete": "Poista",
            "dconfirm":"Haluatko varmasti poistaa?",
            "cirequired": "Maa on pakollinen tieto",
            "scountry": "Valitse maa",
            "ccategory": "Kustannuskategoria",
            "sidentified":"Tunnistetut säästöt",
            "language":"kieli",
            "mprofile":"Oma profiili",
            "cpassword":"Vaihda salasana",
            "sout":"Kirjaudu ulos",
            "ddate":"Eräpäivä",
            "rnumber":"Viitenumero",
            "bplan":"Laskutussopimus",
            "ppattern":"Salasanassa tulee olla vähintään yksi iso kirjain, yksi pieni kirjain, yksi numero ja yksi erikoismerkki. Esim. Pass@123",
            "ecperson": "Syötä yhteyshenkilön nimi",
            "dnavailable":"Tietoja ei saatavilla",
            "company":"Yhtiö",
            "scompany":"Valitse yhtiö",
            "subcompany":"Yritys",
            "ssubcompany":"Valitse yritys",	
            "month": "Laskutusjakso",
            "smonth": "Valitse laskutusjakso",
            "bplangrp":"Liittymätyypit",	
            "cmasters":"Asiakkuuden hallinta",
            "all":"Kaikki",
            "unamemail":"Käyttäjänimi / Sähköpostiosoite",
            "scostcenter":"Valitse kustannuspaikka",  
            "cprequired":"Yhteyshenkilö vaaditaan!",  
            "contactno": "Puhelinnumero",
			"econtactno": "Syötä puhelinnumero",
			"rcontactno": "Puhelinnumero on pakollinen tieto!",
			"icontactno": "Puhelinnumero ei kelpaa!",
			"email": "Sähköpostiosoite",
			"eemail": "Anna sähköpostiosoite",
			"remail": "Sähköpostiosoite on pakollinen tieto!",
			"iemail": "Sähköpostiosoite ei kelpaa!",
			"back": "Takaisin",
            "name":"Nimi",
            "status":"Tila",
            "submit": "Lähetä",
            "addNewOperator": "Lisää uusi operaattori",
			"exportAllData": "Vie tiedostoon kaikki data",
			"exportOptList": "Vie tiedostoon Optimointilistaus",
            "contractaction": "Toiminto",

        },

        "message":{
            "sregister": "Rekisteröinti onnistui!",
			"eregister": "Jokin seuraavista tiedoista on jo olemassa: Yrityksen nimi, Y-tunnus, sähköpostiosoite!",
            "ivalidContract":"Ei käytössä. Lisää ensin voimassa oleva sopimus",
			"clienterminatedval":"Ei käytössä. Asiakkaan tila on de-aktivoitu",
			"caddsuccess": "Asiakas lisätty onnistuneesti!",
            "cadderror": "Jokin seuraavista on jo käytössä: Y-tunnus, Asiakasnumero, Yrityksen nimi tai sähköpostiosoite!",
			"sprofileedit": "Profiili päivitetty onnistuneesti!",
            "eprofileedit": "Jokin seuraavista on jo käytössä: Yrityksen nimi tai sähköpostiosoite!",
            "srecordsave": "Yritys osoitettu onnistuneesti!",
			"errorinsave": "Virhe tiedon tallennuksessa!",
            "subcompanyedit":"Yritys päivitetty onnistuneesti!",
			"subcompanyduplicate":"Yritys on jo olemassa!",
            "cudeletesuccess":"Käyttäjä lisätty onnistuneesti!",
            "cudeleteerror":"Virhe Client-käyttäjän poistossa!",
            "cuseraddError":"Sähköpostiosoite on jo käytössä",
            "scontract": "Sopimus lisätty onnistuneesti!",
			"sdcontract": "Sopimus tallennettu luonnokseksi!",
			"econtract": "Sopimus päivitetty onnistuneesti!",
			"stcontract":"Sopimus päätetty onnistuneesti!",			
			"sresetpassword": "Password Reset Successfully!",
			"eresetpassword": "Virhe tapahtui!",
			"sctoCompany": "Liidi konvertoitu asiakkaaksi onnistuneesti",
			"ectoCompany": "Virhe konvertoinnissa!",
			"ccdetailssuccess": "Sopimustiedot lisätty onnistuneesti!",
			"ccdetailsedit": "Sopimustiedot muokattu onnistuneesti!",
			"ccdetailserror": "Virhe tapahtui!",
			"duploadsuccess": "Dokumentti ladattu onnistuneesti",
			"duploadConflict": "Dokumentin nimi on jo käytössä",
			"duploaderror": "Jotain meni vikaan.",
			"docdelete": "Dokumentti poistettu onnistuneesti!",
			"docerror": "Virhe dokumentin poistamisessa!",
			"shelp": "Tiedosto tallennettu!",
			"fhelp": "Epäonnistui",
			"sbillingplan": "Sopimuspohja lisätty onnistuneesti",
			"ubillingplan": "Sopimuspohja päivitetty onnistuneesti",
			"abillingplan": "Saman niminen Sopimuspohja on jo olemassa!",
			"dbillingplan": "Sopimuspohja poistettu onnistuneesti!",
			"edbillingplan":"Sopimuspohja on käytössä, ei voida poistaa!    ",
			"error": "Error!",
			"cpaddsuccess": "Maksu lisätty onnistuneesti!",
			"paymentamountval":"Summan pitää olla suurempi kuin 0!",
			"condemandsupaddsuccess": "Tukipalvelut lisätty onnistuneesti!",
			"ondemandhourval":"Tuntimäärän tulee olla suurempi kuin 0",
		},

        "customer":{
            "cdashboard": {
                "dashboard": "Päänäkymä",
                "costAndUsage":"Tervetuloa",
                "cdashboard": "Yrityksen päänäkymä",
                "utype": "Liikennelaji",
                "sutype": "Valitse liikennelaji",
                "all": "Kaikki",
                "minutes": "Puhelut (min/kk)",
                "sms": "Viestit (kpl/kk)",
                "tisms": "Viestit laskulla (kpl/kk)",
                "timinutes": "Puhelut laskulla (min/kk)",
                "timinutes1": "Tiedonsiirto laskulla (Mt/kk)",
                "tiamount": "Laskun kokonaissumma",
                "tgraph": "Viivadiagrammi",
                "ustatistics": "Pylväsdiagrammi",
                "message": "Viestit (kpl/kk)",
                "duration": "Kesto (min/kk)",
                "units": "Yksiköt",
                "months": "Kuukaudet",
                "pnumber": "Puhelinnumero",
                "name": "Nimi",
                "tidata": "Tiedonsiirto laskulla (Mt/kk)",
                "period":"Tarkasteltavan ajanjakson pituus",
                "speriod":"Valitse tarkastelujakson pituus",
                "subcompany":"Yritys",
                "ssubcompany":"Valitse yritys",
                "costcategory":"Kustannuskategoria",
                "scostcategory":"Valitse kustannuskategoria",
                "mfees":"Kausimaksut",
                "calls":"Puhelut",
                "data":"Tiedonsiirto",
                "cost":"Liikennekustannukset",
                "tmsg":"Viestit yhteensä",
                "tcall":"Puhelut yhteensä",
                "tdata":"Tiedonsiirto yhteensä",
                "tmfee":"Kausimaksut yhteensä",
                "ttcost":"Liikennekustannukset yhteensä",
                "tcost":"Kustannukset yhteensä",             
            },
            
            "cDashboard_2":{
                "dashboard_2":"Säästöt",
				"totalNumberOfPlans":"Aktiivinen liittymämäärä (Kpl)",
				"changeInNumberOfPlans":"Liittymämäärän muutos ajanjaksolta (Kpl)",
				"totalCostWithOptimization":"Kokonaiskustannus optimoinnilla (€)",
				"totalCostWithoutOptimization":"Kokonaiskustannus ilman optimointia (€)",
				"totalSavings":"Kokonaissäästö ajanjaksolta (€)",
				"numberOfPlans":"Liittymämäärät",
				"spendAndSavings":"Kustannukset ja säästöt",
                "costWithOptimization":"Kokonaiskustannus optimoinnilla",
				"costWithoutOptimization":"Laskutus ilman optimointia",
				"savings":"Säästöjä"
            },
            
            "uinvoice": { 
                "uinvoice": "Lataa lasku",
                "cfile": "Valitse tiedosto",
                "nfchoosen": "Ei valittua tiedostoa",
                "updf": "Lataa PDF",
                "usummary": "Latauksen yhteenveto",
                "itable": "Ladatut laskut",
                "idate": "Laskun päivämäärä :",
                "pnumber": "Numero",
                "name": "Käyttäjä",
                "dinvoice": "Avaa lasku",
                "cname": "Yrityksen nimi",
                "sms": "Viestit (kpl/kk)",
                "minutes": "Puhelut (min/kk)",
                "items": "Käyttäjää",
                "page": "sivu",
                "goto": "Mene sivulle",
                "pspftupload":"Valitse ladattava PDF lasku",
                "processing":"Aineistoa käsitellään",
                "hon":"Odota!",
                "llyprhncipyet":"Näyttää siltä, että edellinen latauspyyntösi ei ole vielä valmis",
                "wwnyiymowadone":"Saat tiedon aineiston valmistumisesta sähköpostiisi",
                "nuftmonth":"Ei latauksia tälle kuulle",
                "tiahbparsed":"Tämä lasku on jo käsitelty",
                "Tfinvinvoice":"Tämä tiedosto ei ole kelvollinen PDF-lasku!",
                "tyou":"Kiitos",
                "wnext":"Mitä seuraavaksi?",
                "patndfuimtawhile":"Tietojen hakeminen laskulta saattaa kestää hetken.",
                "bitbsoiyjuploaded":"Alla yhteenveto juuri lataamistasi laskuista:",
                "iparsed":"Käsitelty",
                "scname":"Yrityksen nimi",
                "scname1":"Yrityksen nimi:",
                "idate1": "Laskun päivämäärä",              
                "sfiles":"Valitse tiedostot",
                "dfzone":"Pudota laskut tähän",
                "ufiles":"Lataa laskut",
                "size":"Tiedoston koko",
                "actions":"Muokkaa",
                "uall":"Lataa kaikki"
            },

            "cseowise": {                    
                "csummaries": "Optimoitavat kokonaiskustannukset",
                "eorganizationwise": "Koko yritys",
                "month": "Laskutusjakso",
                "smonth": "Valitse laskutusjakso",
                "name": "Käyttäjä",
                "pnumber": "Numero",
                "npidentified": "Optimoitu liittymä",
                "optimized": "Optimoitu",
                "items": "Käyttäjää",
                "page": "sivu",
                "goto": "Mene sivulle"
            },

            "csbccenter": {
                "csummaries": "Optimoitavat kokonaiskustannukset",
                "month": "Laskutusjakso",
                "smonth": "Valitse laskutusjakso",
                "name": "Käyttäjä",
                "pnumber": "Numero",
                "npidentified": "Uusi liittymä (ehdotus)",
                "optimized": "Optimoitu",
                "items": "Käyttäjää",
                "page": "sivu",
                "goto": "Mene sivulle",
                "famount":"Ennustettu määrä"
            },

            "useowise": {
                "eowise": "Koko yritys",
                "month": "Laskutusjakso",
                "smonth": "Valitse laskutusjakso",
                "name": "Käyttäjä",
                "pnumber": "Numero",
                "sms": "SMS (kpl/kk)",
                "minutes": "Puhelut (min/kk)",
                "items": "Käyttäjää",
                "page": "sivu",
                "goto": "Mene sivulle"
            },   

            "usbccenter": {
                "month": "Laskutusjakso",
                "smonth": "Valitse laskutusjakso",
                "sccenter": "Valitse kustannuspaikka",
                "name": "Käyttäjä",
                "pnumber": "Numero",
                "sms": "SMS (kpl/kk)",
                "esms": "EU SMS (kpl/kk)",
                "minutes": "Puhelut (min/kk)",
                "eminutes": "EU puhelut (min/kk)",
                "edata": "EU tiedonsiirto (Mt/kk)",
                "items": "Käyttäjää",
                "page": "sivu",
                "goto": "Mene sivulle"
            },  

            "topusersreport": {				
                "tusers":"Top käyttäjät",
                "tuserc":"Top käyttäjät kustannuksittain",                  
                "minutes":"Puhelut",
                "sms":"SMS",
                "pnumber": "Numero",
                "costcategory":"Kustannuskategoria",
                "scostcategory":"Valitse kustannuskategoria",
                "sooptcost":"Näytä vain optimoitavat kustannukset"
            },

            "overchargedplansreport":{
                "overchargedplans": "Laskutusvirheet"
            },

            "unUsedMonthCount": {
				"unusedMonthCount": "Käyttämättömät kuukaudet lasketaan"
			},

            "umanagement": {
                "umanagement": "Puhelinetukäyttäjien hallinta",
                "name": "Käyttäjä",
                "pnumber": "Numero",
                "alogin": "Salli sisäänkirjautuminen",
                "edit": "Muokkaa",
                "delete": "Poista",
                "items": "Käyttäjää",
                "page": "sivu",
                "goto": "Mene sivulle",
                "unirequired":"Käyttäjänimi on pakollinen tieto!",
                "pniinvalid":"Puhelinnumero ei ole kelvollinen",
                "pnirequired":"Puhelinnumero on pakollinen tieto",
                "eiirequired":"Sähköpostiosoite on pakollinen tieto",
                "eiiinvalid":"Sähköpostiosoite ei ole kelvollinen",
                "eeid": "Syötä yhteyshenkilön sähköpostiosoite",
                "euname":"Syötä käyttäjätunnus",  //need to varify
                "mbenifit": "Matkapuhelinetu",
                "5gBenifit": "5G-liittymä",
                "importusers":"Tuo käyttäjiä"
            },

            "pdetails": {
                "pdetails": "Matkapuhelinliittymien hallinta",
                "pgroups": "Liittymäryhmät",
                "mfoptimization": "Valitse optimointiin",
                "vplans": "Puheliittymät",
                "pname": "Liittymän nimi",
                "cprice": "Sopimushinta (€/kk)",
                "SMSlprice":"SMS listahinta (€/kpl)",
                "SMScprice":"SMS sopimushinta (€/kpl)",
                "minlprice":"Puheluiden listahinta (€/min)",
                "mincprice":"Puheluiden sopimushinta (€/min)",
                "considered": "Käytetään optimoinnissa",
                "dslab": "Datarajoitin (Mt/kk)",
                "edit": "Muokkaa",
                "sms": "SMS (kpl/kk)",
                "minutes": "Minuutit (min/kk)",
                "ipunlimited": "Rajoittamaton data (Mt/kk)",
                "lprice": "Listahinta (€/kk)",
                "cfoptimization ": "Käytetään optimoinnissa",
                "dplan": "Vain kotimaan käyttö sallittu",
                "ptname": "Liittymälaji",
                "saoplans": "SMS-lisäpaketit",
                "mbplans": "Mobiililaajakaistat",
                "m2mplans":"M2M-liittymät",
                "gplangroup":"Julkiset liittymätyypit",
                "csplangroup":"Asiakaskohtaiset liittymätyypit",
                "dataMbits":"Data Mbits/s"
            },

            "epdetails": {
                "epdetails": "Liittymän muokkaus",
                "pname": "Liittymän nimi",
                "lprice": "Listahinta (€/kk)",
                "cprice": "Sopimushinta (€/kk)",
                "SMSlprice": "SMS listahinta (€/kpl)",
                "SMScprice": "SMS sopimushinta (€/kpl)",
                "minlprice": "Puheluiden listahinta (€/min)",
                "mincprice": "Puheluiden sopimushinta (€/min)",
                "tbconsidered": "Käytetään optimoinnissa",
                "dslab": "Datarajoitin (Mt/kk)",
                "ecprice": "Muokkaa sopimushintaa",
                "eSMScprice": "Syötä SMS-viestien sopimushinta (€/kpl)",
                "emincprice": "Syötä puheluiden sopimushinta (€/min)",
                "edslab": "Syötä datarajoittimen arvo",
                "cpirequired":"Sopimushinta (€/kk) on pakollinen tieto",
                "dsirequired":"Datarajoitin (Mt/kk) on pakollinen tieto",
                "SMScpirequired":"SMS viestien listahinta on pakollinen tieto!",
                "mincpirequired":"Puheluiden listahinta on pakollinen tieto!",
            },

            "ostatistics": {
                "optimization": "Optimointi",
                "ostatistics": "Optimointi",
                "usdetails": "Optimointiaineiston tiedot",
                "idate": "Laskun päiväys",
                "ucount": "Kaikki liittymät ja laitteet",
                "ciamount": "Laskun summa (€/kk)",
                "oucount": "Optimoidut liittymät (kpl)",
                "tovalue": "Optimoidut kustannukset (€/kk)",
                "roptimize": "Optimoi uudelleen",
                "olist": "Optimoidut liittymät",
                "boptimization": "Kustannus ennen optimointia (€/kk)",
                "aoptimization": "Kustannus optimoinnin jälkeen (€/kk)",
                "savings": "Säästöt (€/kk)",
                "disconnectedSavings": "Säästö irtisanottuna",
                "name": "Käyttäjä",
                "pnumber": "Numero",
                "camount ": "Nykykustannus (€/kk)",
                "ebplan": "Optimoitu liittymä",
                "ebpamount": "Optimoitu kustannus (€/kk)",
                "uoplan": "Ei optimoidut liittymät",
                "moptimization": "Lisää optimoitaviin liittymiin",
                "camount": "Nykykustannus (€/kk)",
                "elbplan": "Optimoitu liittymätyyppi",
                "uuplan": "Käyttämättömät liittymät",
                "mfoptimization": "Lisää optimoitaviin liittymiin",
                "ebpamount ": "Optimoitu kustannus (€/kk)",
                "ddetails": "Kotimaan käyttö",
                "originals": "Toteutunut käyttö",
                "forecast": "Ennuste",
                "considered": "Optimoinnissa käytetty arvo",
                "sms": "SMS",
                "minutes": "Puhelut",
                "data": "Tiedonsiirto",
                "ndetails": "Pohjola/Baltia-käyttö",
                "eudetails": "EU-käyttö",
                "items": "Käyttäjää",
                "page": "sivu",
                "goto": "Mene sivulle",
                "dbplan":"DNA optimoitu liittymä",
                "dbpamount":"DNA optimoitu kustannus (€/kk)",
                "tbplan":"Telia optimoitu liittymä",
                "tbpamount":"Telia optimoitu kustannus (€/kk)",
                "llyprhncipyet":"Näyttää siltä, että edellinen latauspyyntösi ei ole vielä valmis.",
                "tsoftipctadministrator.":"Aloittaaksesi optimoinnin tälle laskulle, ota yhteyttä palvelun ylläpitäjään.",
                "scname":"Yrityksen nimi",
                "inumber":"Laskun numero",
                "cplan ": "Nykyinen liittymä",
                "sLimit": "Minimisäästö",
                "apply": "Käytä"
            },

            "mprofile": {
                "mprofile": "Oma profiili",
                "username": "Käyttäjänimi",
                "name": "Etunimi",
                "cno": "Puhelinnumero",
                "update": "Päivitä",
                "efname": "Syötä Etunimi",
                "eeid": "Syötä sähköpostiosoite",
                "epnumber": "Syötä puhelinnumero",
                "scountry": "Valitse maa",
                "fnirequired": "Etunimi on pakollinen tieto",
                "cnirequired": "Puhelinnumero on pakollinen tieto",
                "cirequired": "Maa on pakollinen tieto",
                "contractdetails": "Operaattorisopimuksen tiedot",
                "accountdetails":"tilin tiedot",
				"userdetails":"Käyttäjän tiedot",
				"settings":"asetukset",
				"language":"kieli",
				"slanguage":"Valitse kieli",
				"uploadlogo":"Lähetä yrityksen logo",
				"allowfileformat":"Sallitut tiedostotyypit: .png",
				"theme":"Sovelluksen teema",
				"stheme":"Valitse sovelluksen teema",
            },
            
            "cpassword": {
                "cpassword": "Vahvista uusi salasana",
                "opassword": "Vanha salasana",
                "npassword": "Uusi salasana",
                "eopassword": "Syötä vanha salasana",
                "enpassword": "Syötä uusi salasana",
                "ecpassword": "Syötä uusi salasana",
                "opirequired": "Vanha salasana on pakollinen tieto",
                "npirequired": "Uusi salasana on pakollinen tieto",
                "cpirequired": "Vahvista uusi salasana on pakollinen tieto",
                "opincorrect":"Vanha salasana ei ole oikein!"  ,
                "pdnmatch":"Salasanat eivät täsmää" 
            }
        },

        "admin": {
            
            "scheduleddownloads":{
                "reportArchives": "Raporttiarkisto",
                "downloads": "Lataukset",
				"btnAllCostsandUsage": "Kokonaiskustannukset ja -käyttö",
				"btnOptimizableCostsandUsage": "Optimoitavat kustannukset ja - käyttö",
				"download": "Lataa",
				"company": "Yritys",
				"ScheduledExportName": "Raportin nimi",
				"Status": "Tila",
				"action": "Lataa",
				"sheduledsuccessmsg": "Excel raportin vienti on aloitettu. Saat ilmoituksen sähköpostiisi, kun tiedosto on ladattu Lataukset näkymään.",
				"sheduledfailedmsg": "Excel vienti epäonnistui. Yritä hetken kuluttua uudelleen.",
                "Scheduled": "Aikataulutettu",
				"Ready": "Valmis",
				"Failed": "Epäonnistui",
                "DashboardAllCostsandUsage": "Kokonaiskustannukset ja käyttö",
				"DashboardOptimizableCostsandUsage": "Optimoitavat kustannukset ja käyttö",
                'BasePlaneTypes': 'Liittymätyypit',
                'topUserReport': 'Top käyttäjät',
                "date": "Lataus pvm",
                "sdate": "Valitse Päivämäärä",
                "optimizedUsersReport":"Optimoidut liittymät",
				"unOptimizedUsersReport":"Ei muutostarvetta liittymät",
				"noUsageUsersReport":"Käyttämättömät liittymät",
                "dashBoardReports":"Käyttö- ja kustannukset",
				"usageReport":"Liittymät",
				"optimizationReport":"Optimointi",
				"execReport":"Pääraportti",
                "comingSoon": "Tulossa"
			},

            "failedInvoice":{
                "failedInvoiceDownload":"Laskun lataus epäonnistui",
				"ClientId":"Asiakastunnus",
				"OperatorId":"Käyttäjätunnus",
				"InvoiceNumber":"Laskun numero",
				"Action":"Toiminto",
				"Client":"Asiakas",
				"OperatoreName":"Operaattorin nimi",
				"FailedDate":"Laskun haku epäonnistui (pvm)",
				"companyName":"Yrityksen nimi"
            },

            "fullAutomation":{
                "fullAutomation":"Laskun haku automaatio",
				
				"invoiceAutomation":"Automaattinen laskun haku",
				"invoiceManual":"Manuaalinen laskun haku",
				"invoicePending":"Laskun haku odottaa",
				"invoiceUploadedParsed":"Analysoitu/Ladattu (Valittu kuukausi)",
				"uncatagorisedCostElement":"Kategorisoimattomat kustannuslajit",
				"scheduledOptimization":"Ajastettu optimointi",

				"companyName":"Yrityksen nimi",
				"operatorName":"Operaattorin nimi",
                "adminname":"Pääkäyttäjän nimi",
				"automationType":"Automaatiotyyppi",
				"automatic": "Automaattinen",
				"manual": "Manuaalinen",
                "lastUploadedDate": "Viimeisin lataus",
				"credentials":"Portaalitunnukset",
				"available": "Asetettu",
				"unavailable": "Ei asetettu",
				"credentialsStatus":"Portaalitunnusten tila",
				"successful": "Onnistunut",
				"failed": "Epäonnistui",

				"invoiceUploadReminderDate":"Lataa laskut muistutus (pvm)",

				"optimizationCompleted":"Optimointi valmis",
				"yes": "Kyllä",
				"no": "Ei",
				"downloadReports":"Kustannus- ja käyttöraportit",

				"operator": "Operaattori",
				"costGroup":"Kustannusryhmä",
				"costElement":"Kustannuselementti",
				"invoiceNumber":"Lasku nro",
				"costCatagory":"Kustannuskategoria",
				"createdOn":"Luontipäivämäärä",
				"action":"Toiminto",

				"subCompanyName":"Yhtiö",
				"parsingStatus":"Laskuanalyysin tila",
                "pending": "Odottaa",
				"completed": "Valmis",
                "!!":"!!",

                "lastMonthInvoiceUploaded":"Viime kuussa ladatut laskut",
				"lastUploadedInvoiceDate":"Viimeksi ladatut laskut pvm"
			},

            "unusedMonthCount":{
				"unusedMonthCountTitle":"Käyttämättömät liittymät",
				"company":"Yritys",
				"invoiceNumber":"Laskun numero",
				"phoneNumber":"Puhelinnumero",
				"userName":"Käyttäjänimi",
                "monthlyFees":"Kausimaksut (€)",
                "unUsedMonthCount":"Käyttämättömät kuukaudet yhteensä",
                "planTypeName":"Liittymätyyppi"
			},


            "activitylog":{
				"activitylog": "Tapahtumaloki",
                "date": "Päivämäärä",
                "from": "Mistä pvm.",
				"to": "Mihin pvm.",
				"all": "Kaikki",
				"company": "Yritys",
				"consultant": "Konsultti",
				"client": "Asiakas",
				"clientPlaceHolder": "Valitse asiakkaat",
				"module": "Käyttöosiot",
				"modulePlaceHolder": "Valitse tarkasteltava käyttöosio",				
			},
            
            "consultantbilling":{
                "consultant": "Konsultti",
				"consultantbilling": "Konsulttilaskutus",
                "place_holder_consultant": "Valitse konsultti",
                "month": "Kuukausi",
                "ConsultantTotalBillingDetails": "Konsulttilaskutuksen tiedot",

                "businessID": "Y-tunnus",
				"BidgateID": "Käyttäjä ID",
				"CompanyName": "Yritys",
				"NoOfPlans": "Liittymämäärä",
				"NoOfCustomerAdditionalUsers": "Asiakkaan lisäkäyttäjät",
				"AutomatedInvoiceUpload": "Automatisoitu laskunhaku",
				"IsNew": "Uusi",
				"TotalNoofPlans": "Liittymät yhteensä",
				"NoOfConsultantAdditionalUsers": "Konsultin lisäkäyttäjät",
				"TotalAutomatedInvoiceUpload": "Automatisoitu laskunhaku yhteensä",
				"NewlyAssignedCompanies": "Uudet Asiakkaat/kk",
				"TotalNoofCustomerAdditionalUsers": "Asiakkaan lisäkäyttäjät yhteensä",
                "amount": "Summa (€)",
                "total":"Yhteensä (€)",
			},

            "dashboard": {
                "dashboard": "Päänäkymä",
                "costAndUsage": "Kustannukset ja käyttö",
                "welcome":"Tervetuloa",
                "adashboard": "Admin Päänäkymä",
                "utype": "Liikennelaji",
                "sutype": "Valitse liikennelaji",
                "all ": "Kaikki",
                "optcost":"Optimoitavat kustannukset",
                "datal":"Tiedonsiirto",
                "messagel": "Viestien",
                "minutes ": "Puhelut (min/kk)",
                "sms ": "Viestit (kpl/kk)",
                "tisms": "Viestit laskulla (kpl/kk)",
                "timinutes": "Puhelut laskulla (min/kk)",
                "tidata": "Tiedonsiirto laskulla (Mt/kk)",
                "tiamount": "Laskun kokonaissumma",
                "tgraph": "Viivadiagrammi",
                "ustatistics": "Pylväsdiagrammi",
                "message": "Viestit (kpl/kk)",
                "duration": "Kesto (min/kk)",
                "units": "Yksiköt",
                "months": "Kuukaudet",
                "pnumber": "Puhelinnumero",
                "name": "Nimi",
                "period":"Tarkasteltavan ajanjakson pituus",
                "speriod":"Valitse ajanjakso",
                "company":"Yhtiö",
                "scompany":"Valitse yhtiö",
                "subcompany":"Yritys",
                "ssubcompany":"Valitse yritys",
                "costcategory":"Kustannuskategoria",
                "scostcategory":"Valitse kustannuskategoria",
                "mfees":"Kausimaksut",
                "calls":"Puhelut",
                "data":"Tiedonsiirto (Mt/kk)",
                "cost":"Liikennekustannukset",
                "tmsg":"Viestit yhteensä",
                "tcall":"Puhelut yhteensä",
                "tdata":"Tiedonsiirto yhteensä",
                "tmfee":"Kausimaksut yhteensä",
                "ttcost":"Liikennekustannukset yhteensä",
                "tcost":"Kustannukset yhteensä",
                "costelement":"Kustannuslaji",
                "scostelement":"Valitse Kustannuslaji",                
            },

            "dashboard_2":{
				"dashboard_2":"Säästöt",
				"totalNumberOfPlans":"Aktiivinen liittymämäärä (Kpl)",
				"changeInNumberOfPlans":"Liittymämäärän muutos ajanjaksolta (Kpl)",
				"totalCostWithOptimization":"Kokonaiskustannus optimoinnilla (€)",
				"totalCostWithoutOptimization":"Kokonaiskustannus ilman optimointia (€)",
				"totalSavings":"Kokonaissäästö ajanjaksolta (€)",
				"numberOfPlans":"Liittymämäärät",
				"spendAndSavings":"Kustannukset ja säästöt",
                "costWithOptimization":"Kokonaiskustannus optimoinnilla",
				"costWithoutOptimization":"Laskutus ilman optimointia",
				"savings":"Säästöjä"
			},

            "uinvoice": {
                "uinvoice": "Lataa lasku",
                "cfile": "Valitse tiedosto",
                "nfchoosen": "Ei valittua tiedostoa",
                "updf": "Lataa PDF",
                "usummary": "Latauksen yhteenveto",
                "idate": "Laskun päivämäärä:",
                "pnumber": "Puhelinnumero",
                "name": "Nimi",
                "cname": "Yrityksen nimi",
                "itable": "Ladatut laskut",
                "pstatus": "Lasku ladattu",
                "dinvoice": "Avaa lasku", 
                "sms": "Viestit (kpl/kk)",
                "minutes": "Puhelut (min/kk)",
                "pspftupload":"Valitse ladattava PDF lasku",
                "processing":"Aineistoa käsitellään",
                "nuftmonth":"Ei latauksia tälle kuukaudelle!",
                "tiahbparsed":"Tämä lasku on jo käsitelty",
                "tfinvinvoice":"TTämä tiedosto ei ole kelvollinen laskukopio",
                "Euros":"Euroa",
                "eorganization":"Koko organisaatio",
                "customers":"Asiakkaat",
                "currency":"Valuutta",
                "scname":"Yrityksen nimi",
                "scname1":"Yrityksen nimi:",
                "idate1": "Laskun päivämäärä",
                "sfiles":"Valitse tiedostot",
                "dfzone":"Pudota laskut tähän",
                "ufiles":"Lataa tiedostot",
                "size":"Koko",
                "actions":"Muokkaa",
                "uall":"Lataa kaikki",
                "billingperiod":"Laskutuskausi:",
                "umtitle":"Tiedoston lataustila",
                "uploaded":"Ladattu",
                "inprogress":"Käynnissä",
                "failed":"Epäonnistui"
            },

            "cseowise": {
                "csummaries": "Optimoitavat kokonaiskustannukset",
                "eowise": "Yrityksittäin",
                "month": "Laskutusjakso",
                "samonth": "Valitse laskutusjakso",
                "name": "Nimi",
                "pnumber": "Numero",
                "npidentified": "Uusi liittymä (ehdotus)",
                "optimized": "Optimoidaan?",
                "sidentified":"Säästöjä löydetty",
                "sms": "SMS (kpl/kk)",
                "minutes": "Puhelut (min/kk)"
            },

            "csbccenter": {
                "csummaries": "Optimoitavat kokonaiskustannukset",
                "name": "Nimi",
                "pnumber": "Numero",
                "npidentified": "Uusi liittymä (ehdotus)",
                "optimized": "Optimoidaan?",
                "sidentified":"Tunnistetut säästöt",
            },

            "useowise": {
                "eowise": "Yrityksittäin",
                "name": "Nimi",
                "pnumber": "Numero",
                "sms": "SMS (kpl/kk)",
                "minutes": "Puhelut (min/kk)"
            },   

            "usbccenter": {
                "name": "Nimi",
                "pnumber": "Numero",
                "sms": "SMS (kpl/kk)",
                "minutes": "Puhelut (min/kk)"
            },

            "plantypereport": {
                "plantype": "Liittymätyypit",
                "month": "Kuukausi",
                "samonth": "Valitse laskutusjakso",
                "cname": "Yritys",
                "scname": "Valitse yhtiö",
                "sucname": "Yritys",
                "sasucname": "Valitse yritys",
                "bplantype": "Liittymätyypit",
                "nousers": "Käyttäjien lukumäärä",
                "uname": "Käyttäjänimi",
                "pno": "Puhelinnumero",
                "cp": "Nykyinen liittymä"
            },

		    "topusersreport": {				
				"tusers":"Top käyttäjät",
				"tuserc":"Top käyttäjät kustannuksittain",
				"minutes":"Puhelut (min/kk)",
				"sms":"Viestit (kpl/kk)",
                "pnumber": "Puhelinnumero",
                "costcategory":"Kustannuskategoria",
                "scostcategory":"Valitse kustannuskategoria",
				"sooptcost":"Näytä vain optimoitavat kustannukset"
            },

            "overchargedplansreport": {
                "overchargedplans": "Laskutusvirheet",
                "tdifference": "Erotus yhteensä (€)",
                "cplan": "Nykyinen liittymätyyppi",
				"pcharged": "Laskutettu (€/kk)",
				"cprice": "Sopimushinta (€/kk)",
				"difference": "Erotus",
            },	

            "clientcontractsreport":{
                "clientcontracts": "Sopimukset",
				"acontracts": "Aktiiviset sopimukset",
				"inacontracts": "Passiiviset sopimukset",
				"cname": "Yritys",
				"type": "Asiakastyyppi",
				"bpname": "Sopimuspohjan nimi",
				"csdate": "Sopimuksen alkamispäivä",
				"cedate": "Sopimuksen päättymispäivä",
				"bcycle": "Laskutussykli",
				"Atype" : " "
			},

            "operators": {
                "operators": "Operaattorit",
                "name": "Nimi",
                "pnumber": "Numero",
                "pniinvalid":"Puhelinnumero ei kelpaa",
                "eiiinvalid":"Sähköpostiosoite ei kelpaa",
                "ccirequired":"Maa on pakollinen tieto",
                "aystcancel":"Haluatko varmasti peruuttaa?",
                "eeid": "Syötä yhteyshenkilön sähköpostiosoite",
            },

            "customers": {
                "customers":"Asiakkaat",
                "cname": "Asiakas",
                "pnumber": "Numero"
            },

            "cacustomer": {
                "acustomer":"Lisää uusi",
                "cname": "Yritys",
                "pnumber": "Puhelinnumero",
                "password": "Salasana",
                "cpassword ": "Vahvista salasana",
                "address": "Osoite",
                "ecperson": "Syötä yhteyshenkilön nimi",
                "epnumber": "Syötä yhteyshenkilön puhelinnumero",
                "eeid": "Syötä yhteyshenkilön sähköpostiosoite",
                "epassword": "Syötä yhteyshenkilön salasana",
                "ecpassword": "Vahvista yhteyshenkilön salasana",
                "eaddress": "Syötä katuosoite",
                "cnirequired": "Yrityksen nimi on pakollinen tieto",
                "pnirequired": "Puhelinnumero on pakollinen tieto",
                "pirequired": "Salasana on pakollinen tieto",
                "cpirequired": "Salasanan vahvistus on pakollinen tieto",
                "eiaexists":"Sähköpostiosoite on jo käytössä",
                "pcriteria":"Vaaditaan vähintään kuusi merkkiä, yksi iso kirjain, yksi pieni kirjain, yksi numero ja yksi erikoismerkki Esim. Pass@123",
                "pacpdnmatch":"Salasanat eivät vastaa toisiaan",
                "pniinvalid":"Puhelinnumero ei kelpaa",
                "ecname":"Syötä asiakkaan nimi",
                "casuccessfully":"Asiakas lisätty onnistuneesti"
            },

            "country": {
                "name": "Maa",
                "ecname": "Syötä maan nimi",
                "nirequired": "Nimi on pakollinen tieto"
            },

            "acountry": {
                "acountry":"Lisää uusi",
                "dywtatcountry":"Haluatko aktivoida tämän maan?",
                "name":"Nimi",
                "ecname":"Syötä maan nimi",
                "nirequired":"Nimi on pakollinen tieto"
            },

            "currency":{
                "currency":"Valuutta",
                "anew":"Lisää uusi",
                "name":"Valuutta",
                "abbreviation":"Lyhenne",
                "symbol":"Symboli",
                "action":"Muokkaa",
                "cnirequired":"Valuutan nimi vaaditaan",
                "cairequired":"Valuutta Lyhennys vaaditaan",
                "csirequired":"Valuuttasymboli vaaditaan"
            },   

            "cacurrency":{
                "acurrency":"Lisää uusi",
                "cname":"Valuutan nimi",
                "nirequired":"Nimi on pakollinen tieto",
                "cabbreviation":"Valuutan lyhenne",
                "csymbol":"Valuutan symboli",
                "dywtatcurrency":"Haluatko aktivoida tämän valuutan?"
            },

            "ccenter": {
                "cname": "Asiakas",
                "ccname": "Kustannuspaikan nimi"
            },

            "cgroup": {
                "cgroups":"Kustannusryhmät",
                "cgroup":"Kustannusryhmä"
            },

            "cgacgroup": {
                "acgroup": "Lisää uusi",
                "name": "Nimi",
                "nirequired": "Nimi on pakollinen tieto",
                "iscplan":"Kuukausimaksut?"
            },

            "ccategory": {
                "ccategory": "Kustannuskategoria",
                "ccname": "Kustannuskategoria",
                "utype":"Liikennelaji"
            },

            "ccaccategory": {
                "name": "Kustannuskategoria",
                "sms": "SMS- ja MMS-viestit",
                "voice": "Puhelut",
                "data": "Tiedonsiirto",
                "mfee": "Kuukausimaksut",
                "accategory":"Lisää uusi",
                "eccategory":"Muokkaa kustannuskategoriaa",
                "oirequired":"Operaattori on pakollinen tieto",
                "nirequired":"Nimi on pakollinen tieto",
                "ename":"Lisää nimi",
                "domestic":"Kotimaa",
                "nordicbaltic":"Pohjola-Baltia",
                "eu":"EU",
                "fname":"Suomalainen nimi",
				"efname":"Syötä suomalainen nimi",
				"fnirequired":"Suomenkielinen nimi vaaditaan",                
            },

            "celements": {
                "celements":"Kustannuslaji",
                "ccelements": "Määritellyt kustannuslajit",
                "ucelements": "Määrittelemättömät kustannuslajit",
                "cename":"Kustannuslaji",
                "con":"Luotu",
                "scategory":"Valitse kategoria",
                "accelements":"Automaattisesti kategorisoidut kustannuslajit",
                "approved":"Hyväksytty",
            },

            "plan": {
                "spgroup": "Valitse liittymäryhmä",
                "plan":"Liittymät",
                "pname":"Liittymän nimi",
                "price":"Hinta €/kk",
                "minutes":"Minuutit min/kk",
                "coverage":"Kattavuus",
                "sms":"SMS kpl/kk",
                "ecpirequired":"Hintatieto €/kk on pakollinen tieto",
                "mirequired":"Minuuttimäärä min/kk on pakollinen tieto",
                "sirequired":"SMS-viestien määrä kpl/kk on pakollinen tieto",
                "domestic":"Vain kotimaan käyttö",
                "nlimitations":"Ei rajoituksia",
                "pgroup":"Liittymäryhmä"
            },

            "paplan": {
                "pdetails": "Liittymän perustiedot",
                "pgdetails": "Liittymäryhmän tiedot",
                "bconfiguration": "Liittymän pääominaisuudet",
                "name": "Liittymän nimi",
                "bpcost": "Liittymän listahinta (€/kk)",
                "ptype": "Liittymälaji",
                "dslab": "Datarajoitin",
                "cfoptimization": "Käytä optimoinnissa?",
                "yes": "Kyllä",
                "pcoverage": "Liittymän käyttörajoitus",
                "domestic": "Toimii vain kotimaassa",
                "nlimitations": "Ei rajoituksia",
                "sms":"Viestit (kpl/kk)",
                "aplan":"Uusi liittymä",
                "epdetails":"Muokkaa liittymän tietoja",
                "cprice":"Kuukausimaksu €/kk",
                "spgroups":"Valitse liittymäryhmä",
                "sptype":"Valitse liittymälaji",
                "edslab":"Syötä datarajoitin Mt/kk",
                "vdetails":"Puheominaisuudet",
                "ddetails":"Kotimaan käyttö",
                "pplan":"Pakettiliittymä?",
                "5Gplan":"5G-liittymä",
                "psize":"Paketin koko/kk?",
                "min":"Min",
                "mcost":"Puheluhinta €/min",
                "amcost":"Paketin ylittävät puhelut €/min",
                "ndetails":"Pohjoismaat/Baltia-käyttö",
                "edetails":"EU-käyttö",
                "sdetails":"SMS ominaisuudet",
                "scost":"SMS-hinta €/kpl",
                "ascost":"Paketin ylittävät viestit €/kpl",
                "saodetails":"SMS-paketin ominaisuudet",
                "aosize":"SMS-paketin koko kpl/kk",
                "aoecost":"Paketin ylittävät viestit €/kpl",
                "datadetails":"Tiedonsiirto-ominaisuudet",
                "dtspeed":"Tiedonsiirtonopeus Mbit/s",
                "ipunlimited":"Rajoittamaton data?",
                "edcost":"Paketin ylittävä datasiirto €/Mt",
                "aopsize":"Datalisäpaketin koko Mt/kk",
                "aopcost":"Datalisäpaketin hinta €/kpl",
                "dtpitduitnatbaltics":"Sisältääkö paketti datakäytön Pohjoismaissa ja Baltiassa?",
                "ecost":"Tiedonsiirron hinta €/Mt",
                "dtpitduiteu":"Sisältääkö paketti datakäytön EU-alueella?",
                "ntpwnbcitoftswadusage.":"Huom! Tätä liittymää ei käytetä optimoinnissa, mikäli optimoitavalla liittymällä on datakäyttöä.",
                "epname":"Syötä liittymän nimi",
                "ecprice":"Syötä liittymän kk-maksu",
                "pgroup":"Liittymäryhmä",
                "dtpitdSMSitnatbaltics":"Sisältyykö liittymään SMS-viestejä Pohjola-Baltia alueella?",
                "dtpitSMSuiteu":"Sisältyykö liittymään SMS käyttö EU -alueella?",
                "dtpitdmitnatbaltics":"Sisältyykö liittymään puheluminuutteja Pohjola-Baltia alueella?",
                "dtpitmuiteu":"Sisältyykö liittymään puheluminuutteja EU -alueella?",
                "pspecification":"Liittymän käyttö",
                "general":"Julkisessa myynnissä",
                "csplan":"Asiakaskohtainen liittymä",
                "datatransperspeed":"TIedonsiirtonopeus"
            },

            "pgroups": {
                "name": "Liittymäryhmä",
                "pgroups":"Liittymäryhmät",
                "pgnirequired":"Liittymäryhmän nimi on pakollinen tieto",
                "oirequired":"Operaattori on pakollinen tieto"
            },

            "pgapgroup": {
                "name": "Liittymäryhmä",
                "epgname": "Syötä liittymäryhmän nimi",
                "pgnirequired": "Liittymäryhmän nimi on pakollinen tieto",
                "apgroup":"Uusi liittymäryhmä",
                "oirequired":"Operaattori on pakollinen tieto",
                "dywtatpgroup":"Haluatko aktivoida tämän liittymäryhmän?"
            },

            "spnumber": {
                "spnumber": "Optimoinnissa ohitettavat numerot",
                "number": "Numero",
                "enumber":"Syötä numero",
                "apntbskipped": "Lisää uusi",
                "pnumber": "Numero",
                "pnirequired": "Numerotieto on pakollinen tieto",
                "nirequired":"Numero on pakollinen tieto"
            },

            "help":{
                "help": "Apua",
                "helpfile": "Apua tiedostot",
                "role": "Rooli",
                "srole": "Valitse rooli",
                "page": "Sivu",
                "spage": "Valitse operaattori",
                "language":"Kieli"
            },

            "clientbillingplan": {
                "clientbillingplan": "Sopimuspohjat",
				"name": "Sopimuspohjan nimi",	
            },

            "addclientbillingplan": {
                "addcbillingplan": "Lisää uusi",
                "editcbillingplan": "Muokkaa laskutussopimusta",
                "name": "Sopimuspohjan nimi",
				"ename": "Lisää nimi",
				"rname": "Nimi on pakollinen tieto!",
				"clength": "Sopimuksen pituus",
				"slength": "Valitse sopimuskauden pituus",
				"rclength": "sopimuksen pituus on pakollinen tieto!",
				"apaymentdis": "Maksutapa-alennus %",
				"eapaymentdis": "Anna laskutustapa-alennus%",
				"rapaymentdis": "Maksutapa-alennus % on pakollinen tieto!",
				"punit": "Kustannus €/kpl",
				"units": "Kappalemäärä",
				"total": "Yhteensä (€)",
				"eunit": "Syötä liittymäkohtainen hinta (€/kpl/kk)",
				"runit": "Kustannus on pakollinen tieto!",
				"eunits": "Anna kappalemäärä",
				"runits": "Yksikkö on pakollinen tieto!",
				"supfee": "Palvelun perustamismaksu (€)",
				"esupfee": "Syötä palvelun perustamismaksu (€)",
				"rsupfee": "Perustamiskustannus on pakollinen tieto!",
				"sfpcompany": "Perustamismaksu / yhtiö (€)",
				"esfpcompany": "Syötä yhtiökohtainen perustamismaksu (€)",
				"rsfpcompany": "Yrityskohtainen perustamiskustannus on pakollinen tieto!",
				"nofPlan": "Liittymäkohtainen kustannus (€/kk/kpl)",
				"nofadditionaluser": "Lisäkäyttäjät (€/kpl/kk)",
				"enofadditionaluser": "Syötä lisäkäyttäjän hinta (€/kpl/kk)",
				"rnofadditionaluser": "Lisäkäyttäjän kustannus on pakollinen tieto!",
				"aiupload": "Automatisoitu laskun haku (€/kk)",
				"eaiupload": "Syötä automatisoidun laskunhaun kustannus",
				"raiupload": "Automatisoitu laskunhakukustannus on pakollinen tieto!",
				"ondsupport": "Tukipalvelut (€/h)",
				"eondsupport": "Syötä hinta tukipalveluille (€/h)",
				"rondsupport": "Käyttötuen kustannus on pakollinen tieto!",
				"badminfee": "Pääkäyttäjä BidGate (€/kk)",
				"ebadminfee": "Syötä BidGate Pääkäyttäjän kustannus",
				"rbadminfee": "BidGate Admin kustannus on pakollinen tieto!",
                "cmpau": "Yrityksen lisäkäyttäjät",
				"ecmpauser": "Syötä kustannus Asiakkaan lisäkäyttäjälle €/kk/käyttäjä",
				"rcmpau": "Lisäkäyttäjän kustannus on pakollinen tieto!",
            },

            "ostatistics": {
                "optimization": "Optimointi",
                "ostatistics": "Optimointi ",
                "usdetails": "Optimointiaineiston tiedot",
                "idate": "Laskun päiväys",
                "ucount": "Käyttäjien kokonaislukumäärä",
                "ciamount": "Laskun summa (€/kk)",
                "oiamount":"Optimoitavat kustannukset",
                "olist": "Optimoidut liittymät",
                "uoplan": "Ei optimoidut liittymät",
                "uuplan": "Käyttämättömät liittymät",
                "boptimization": "Kustannus ennen optimointia (€/kk)",
                "savings": "Optimoinnin tuoma Säästö (€/kk)",
                "mfoptimization": "Lisää optimoitaviin liittymiin",
                "name": "Käyttäjä",
                "pnumber": "Numero",
                "cplan ": "Nykyinen liittymä",
                "camount": "Nykyinen kustannus (€/kk)",
                "ebplan": "Optimoitu liittymätyyppi",
                "ebpamount": "Optimoitu kustannus (€/kk)",
                "dbplan":"DNA: n paras suunnitelma",
                "dbpamount":"DNA: n paras suunnitelmasumma (€)",
                "tbplan":"Telian paras suunnitelma",
                "tbpamount":"Telian paras suunnitelmasumma (€)",
                "ddetails": "Kotimaan käyttö",
                "originals": "Toteutunut käyttö",
                "forecast": "Ennuste",
                "considered": "Optimoinnissa käytetty arvo",
                "sms": "SMS",
                "minutes": "Puhelut",
                "data": "Tiedonsiirto",
                "ndetails": "Pohjola/Baltia-käyttö",
                "eudetails": "EU-käyttö",
                "bpamount":"Liittymän listahinta",
                "pcprice":"Liittymän sopimushinta",
                "saopamount":"SMS-paketin hinta",
                "tamount":"Yhteensä: ",
                "oucount": "Optimoidut liittymät (kpl)",
                "tovalue": "Optimoidut kustannukset (€/kk)",
                "scname":"Yrityksen nimi",
                "inumber":"Laskun numero",
                "sLimit": "Minimisäästö",
                "apply": "Käytä",
                "UnusedMonthCount":"Ei käyttöä kk (kpl)",
                "reoptimize_title":"HUOMIO!",
				"reoptimize_btn_ok":"Optimoi uudelleen",
				"reoptimize_btn_close":"Sulje",
				"reoptimize_msg":"Oletko varma, että haluat suorittaa uudelleenoptimoinnin? <br>Huom.: Uudelleen optimointi on tarpeen suorittaa vain, mikäli olet tehnyt muutoksia seuraaviin asioihin:<ul><li>Optimoitavat liittymät</li><li>Liittymien hinnoittelu tai datarajoitin</li><li>Puhelinetukäyttäjät</li></ul>",
                "csavings": "Säästö (€/kk)"
            }, 

            "bpgroup":{
                "bpgroup":"Liittymätyypit",
                "bpgroups":"Liittymätyypit",
                "anew":	"Lisää uusi",
                "oname":	"Operaattori",
                "ptype":"Liittymälaji",
                "soperator":"Valitse operaattori",
                "ccategory"	:"Kustannuskategoria",
                "acategorization":"Automaattinen kategorisointi",
                "action":	"Muokkaa",
                "oirequired":"Operaattori on pakollinen tieto",
                "ptirequired":	"Liittymälaji",
                "sptype":	"Valitse liittymälaji",
                "nirequired":"Nimi on pakollinen tieto",
                "scctype":	"Valitse kustannuskategorian tyyppi",
                "ccirequired":	"Kustannuskategoria on pakollinen tieto"
            },

            "abpgroup":{
                "abpgroup":"Lisää liittymätyyppi",
                "afamandatory":	"Kaikki kentät ovat pakollisia",
                "bgpname":	"Liittymätyypin nimi",
                "nirequired"	:"Nimi on pakollinen tieto",
                "operator":"Operaattori",
                "soperator":	"Valitse operaattori",
                "oirequired":	"Operaattori on pakollinen tieto",
                "sptype"	:"Valitse liittymälaji",
                "sccategory":	"Valitse kustannuskategoria",
                "ccategory"	:"Kustannuskategoria",
                "ccirequired":	"Kustannuskategoria on pakollinen tieto",
                "ptype"	:"Liittymälaji",
                "ptirequired":	"Liittymälaji on pakollinen tieto",
                "acategorization":	"Automaattinen kategorisointi",
                "yes":	"Kyllä",
                "no":	"Ei",
                "save":	"Tallenna",
                "cancel":	"Peruuta",
                "ebpgname":	"Syötä liittymätyypin nimi",
                "sbaseplangroup": "Valitse liittymätyyppi",
				"bspirequired": "Liittymätyyppi on pakollinen tieto!"
            },

            "mprofile": {
                "mprofile": "Oma profiili",
                "username": "Käyttäjänimi",
                "fname": "Nimi",
                "cno": "Puhelinnumero",
                "update": "Päivitä",
                "efname": "Syötä Etunimi",
                "eeid": "Syötä sähköpostiosoite",
                "epnumber": "Syötä puhelinnumero",
                "scountry": "Valitse maa",
                "fnirequired": "Etunimi on pakollinen tieto",
                "cnirequired": "Puhelinnumero on pakollinen tieto",
                "cirequired": "Maa on pakollinen tieto",
                "eiiinvalid":"Sähköpostiosoite ei ole kelvollinen",
                "pnumber":"Puhelinnumero",
                "cnsbdigit":"Puhelinnumeron tulee olla 8-10 numeroa",
                "euname":"Syötä käyttäjänimi"
            },

            "cpassword": {
                "cpassword": "Vahvista uusi salasana",
                "opassword": "Vanha salasana",
                "npassword": "Uusi salasana",
                "eopassword": "Syötä vanha salasana",
                "enpassword": "Syötä uusi salasana",
                "ecpassword": "Syötä uusi salasana",
                "opirequired": "Vanha salasana on pakollinen tieto",
                "npirequired": "Uusi salasana on pakollinen tieto",
                "cpirequired": "Vahvista uusi salasana on pakollinen tieto"
            }     
        },

        "auth":{
            "login":{
                "dhaaccount": "Kiinnostuitko palvelustamme? ",
				"sup": "Rekisteröidy!",
				"laccount": "Sisäänkirjautuminen",
				"email": "Sähköpostiosoite",
				"eiirequired": "Sähköpostiosoite on pakollinen tieto!",
				"eiiinvalid": "Sähköpostiosoite on virheellinen!",
				"password": "Salasana",
				"pirequired": "Salasana on pakollinen tieto!",
				"piinvalid": "Salasana on virheellinen!",
				"pevcredential": "Tarkista käyttäjätunnus tai salasana!",
				"uinactivated": "Käyttäjätiliä ei ole aktivoitu!",
				"login": "Kirjaudu",
				"epassword": "Anna salasana",
				"eeid": "Anna sähköpostiosoite",
				"inactiveuser": "Käyttäjätili ei ole tällä hetkellä voimassa!",
				"operator": "Operator"
            },

            "register":{
                "sup": "Rekisteröidy BidGate-optimointipalvelun käyttäjäksi",
                "supbtn": "Lähetä",
				"eydtcyaccount": "Lähetä yrityksesi tiedot alla olevalla lomakkeella ja valitse tilityyppi. Olemme teihin yhteydessä palvelun käyttöönottoon liittyen pikapuolin.",
				"back": "Takaisin",
				"name": "Etunimi Sukunimi",
				"ename": "Anna Etunimi ja Sukunimi",
				"nirequired": "Nimi on pakollinen tieto",
				"cname": "Yrityksen nimi",
				"ecname": "Anna Yrityksen nimi",
				"cnamerequired": "Yrityksen nimi on pakollinen tieto",
				"businessid": "Y-tunnus",
				"ebusinessid": "Anna Y-tunnus",
				"bidrequired": "Y-tunnus on pakollinen tieto",
				"cno": "Yhteyshenkilön puhelinnumero",
				"ecno": "Syötä puhelinnumero",
				"cnorequired": "Yhteyshenkilön puhelinnumero on pakollinen tieto",
				"cnoinvalid": "Yhteyshenkilön puhelinnumero on virheellinen",
				"email": "Sähköpostiosoite",
				"eeid": "Anna sähköpostiosoite",
				"eiirequired": "Sähköpostiosoite on pakollinen tieto!",
				"eiiinvalid": "Sähköpostiosoite on virheellinen!",
				"rtype": "Valitse tilityyppi",
				"company": "Yrityskäyttäjä",
				"consultant": "Konsultti/Yhteistyökumppani",
            },

            "fpassword":{
                "rpmhbstyreid": "Reset password mail has been sent to your registered email id.",
				"eidnexists.": "Sähköpostiosoitetta ei löydy!",
				"fpassword": "Unohtuiko salasana?",
				"eyetrypassword": "Syötä sähköpostiosoitteesi nollataksesi salasanan:",
				"eiirequired": "Sähköpostiosoite on pakollinen tieto!",
				"eiiinvalid": "Sähköpostiosoite on virheellinen!",
				"back": "Takaisin",
				"eeid": "Anna sähköpostiosoite",
				"submit": "Lähetä"
            },
            
            "rpassword":{
				"title": "Nollaa salasanasi",
				"labelId": "Nollaa salasana",
				"label": "Salasana",
				"placeholderP": "Anna salasana",
				"reqErrMessage": "Salasana on pakollinen tieto!",
				"pattErrMesssage": "Salasanaan vaaditaan ainakin yksi iso kirjain, yksi numero ja yksi erikoismerkki. Esimerkiksi: Sala@987 ",
				"labelCP": "Anna uusi salasana",
				"placeholderCP": "Vahvista uusi salasana",
				"reqErrMessageCP": "Salasanan vahvistus on pakollinen!",
				"notMatchErrMessageCP": "Salasanat eivät täsmää",
				"messageInValid": "Anna voimassa oleva sähköpostiosoite",
				"submit": "Lähetä",
                "linkExpired":"Valitettavasti salasanan palautuslinkki on vanhentunut",
			},
            
            "capage":{
                "cuactivation":	"Käyttäjän aktivointi",
                "ciasuccessfully":	"Käyttäjä aktivoitu"
            }
        },

        "client":{
            "cmanagement":{
                "cmanagement": "Asiakkaat",
                "all":"Kaikki", 
                "lead":"Liidi",
                "company":"Yhtiö",
                "consultant":"Konsultti",
                "hot":"Hot",
                "cold":"Kylmä",
                "lost":"Hävitty",
                "businessid": "Y-tunnus",
                "bidgateaccountid": "BidGate ID",
                "companyname":"Yritys",
                "cadminname":"Yrityksen järjestelmänvalvojan nimi",
				"cadmincontactno":"rityksen järjestelmänvalvojan yhteyshenkilön numero",
				"cadminEmail": "Yrityksen järjestelmänvalvojan sähköpostiosoite",
                "adminname":"Pääkäyttäjän nimi",
                "admincontactno":"Pääkäyttäjän puhelinnumero",
                "adminEmail":"Pääkäyttäjän sähköpostiosoite",
                "type":"Asiakastyyppi",
                "admin":"Pääkäyttäjä",
                "status":"Tila",
                "active":"Aktiivinen",
                "inactive":"Passiivinen",
                "profile": "Profiili",
				"assigncompany":"Osoita yhtiöt",
                "subcompany":"Yritys",
				"addtionalsers":"Lisäkäyttäjät",
				"contractmanagement":"Sopimushallinta",
				"billingmanagement":"Laskutus",
				"operatorcontractdetails":"Operaaattorisopimuksen tiedot",
				"clientpayment":"Maksut",
				"clientonDemandsupport":"Ylläpitotuki",
                "clientInvoiceUserManagement":"Puhelinetukäyttäjien hallinta",
				"clientPlanDetails":"Matkapuhelinliittymien hallinta"
            },

            "aclient": {
				"aclient": "Lisää asiakas",
				"eclient": "Muokkaa profiilia",
				"businessid": "Y-tunnus",
				"ebusinessid": "Syötä Y-tunnus",
				"rbusinessid": "Y-tunnus on pakollinen tieto!",
				"baccountid": "BidGate ID",
				"ebaccountid": "Syötä BidGate ID",
				"rbaccountid": "BidGate ID on pakollinen tieto!",
				"lcname": "Yrityksen nimi",
				"elcname": "Syötä Liidin Yrityksen nimi",
				"rlcname": "Yrityksen nimi on pakollinen tieto!",
				"cname": "Yrityksen nimi",
                "ecname": "Syötä yrityksen nimi",
				"rcname": "Yrityksen nimi on pakollinen tieto!",
				"ccname": "Yrityksen nimi",
				"eccname": "Syötä Konsulttiyhtiön nimi",
				"rccname": "Yrityksen nimi on pakollinen tieto!",
				"address": "Osoite",
				"eaddress": "Syötä osoite",
				"raddress": "Osoite on pakollinen tieto!",
				"country": "Maa",
				"scountry": "Valitse maa",
				"rcountry": "Maa on pakollinen tieto!",
				"operator": "Operaattori",
				"soperator": "Valitse operaattori",
				"roperator": "Operaattori on pakollinen tieto!",
				"laname": "Pääkäyttäjä",
				"elaname": "Syötä Liidin Pääkäyttäjän nimi",
				"rlaname": "Pääkäyttäjän nimi on pakollinen tieto!",
				"cadminname": "Yrityksen järjestelmänvalvojan nimi",
                "ecadminname": "Anna yrityksen järjestelmänvalvojan nimi",
				"rcadminname": "Yrityksen järjestelmänvalvojan nimi vaaditaan!",
				"caname": "Pääkäyttäjän nimi",
				"ecaname": "Syötä Konsultin Pääkäyttäjän nimi",
				"rcaname": "Pääkäyttäjän nimi on pakollinen tieto",
				"password": "Salasana",
				"epassword": "Anna salasana",
				"rpassword": "Salasana on pakollinen tieto!",
				"cpassword": "Vahvista salasana",
				"ecpassword": "Vahvista salasana",
				"rcpassword": "Salasanan vahvistus on pakollinen tieto!",
				"pcriteria": "Vaaditaan vähintään kuusi merkkiä, yksi iso kirjain, yksi pieni kirjain, yksi numero ja yksi erikoismerkki Esim. Pass@123",
				"pacpdnmatch": "Salasanat eivät vastaa toisiaan.",
				"status": "Profiilin tila",
				"isterminated": "Profiilin sulkeminen",
				"terminate": "Suljettu",
				"active": "Aktiivinen",
				"leadstate": "Liidin tila",
				"hot": "Hot",
				"cold": "Kylmä",
				"lost": "Hävitty",
                "method": "Lataustapa",
				"invoiceuploadmethod": "Laskujen lataaminen",
				"automation": "Automaattinen",
				"manual": "Manuaalinen",
				"ReminderDate":"Aseta muistutus!",
                "OperatorUsername": "Operaattoriportaalin käyttäjänimi",
				"OperatorPassword": "Operaattoriportaalin salasana",
				"OperatorUsernameRequired": "Operaattoriportaalin käyttäjänimi on pakollinen tieto!",
				"OperatorPasswordRequired": "Operaattoriportaalin salasana on pakollinen tieto!",
				"OperatorUsernameplace": "Anna Operaattoriportaalin käyttäjänimi",
				"OperatorPasswordplace": "Anna Operaattoriportaalin salasana",
			},

            "vprofile": {
                "profile": "Profiili",
                "rdate": "Rekisteröitymis-pvm",
                "resetpassword":"Päivitä salasana",
                "ctocompany": "Muuta Asiakkaaksi",
                "buissInfo": "Tilin tiedot",
				"adminInfo": "Admin tiedot",
				"statusInfo": "Tila",
				"cmpInfo": "Yrityksen tiedot",
				"leadInfo": "Liidin tiedot",
				"conscmpInfo": "Konsulttiyrityksen tiedot",
                "startingPointSelect":"Lähtökohta",
                "notAvailable":"Ei saatavilla"

            },
            
			"iclient":{
				"iclient":"Kiinnostunut Asiakas",
				"businessid": "Y-tunnus",
				"name":"Nimi",
				"cname":"Yritys",
				"cno":"Puhelinnumero",
				"email":"Sähköpostiosoite",
				"ctype":"Asiakastyyppi",
				"ishowndate": "Aktivointi pvm",
                "status": "Tila",
				"rdate": "Rekisteröitymis-pvm.",
                "notconverted": "Ei Konvertoitu",
				"ctolead": "Konvertoitu Liidiksi",
				"ctocompany": "Konvertoitu Asiakkaaksi",
				"ctoconsultant": "Konvertoitu Konsultiksi",
                "convertlead": "Konvertoi Liidiksi",
				"convertconsultant": "Konvertoi Konsultiksi",
				"ClientTypeNameConsultant": "Konsultti",
				"ClientTypeNameCompany": "Yritys",
			},
			
            "asscompany": {
				"asscompany": "Valitse yritykset",
				"cname": "Konsulttiyhtiön nimi",
				"cmapping": "Yhtiön määrittely",
				"ascompany": "Valitut yritykset",
				"unascompany": "Osoittamattomat yhtiöt"
			},

            "subcompany": {
				"smanagement": "Yritysten hallinta",
				"sname": "Yrityksen nimi",
				"nouser": "Käyttäjien määrä",
                "billingplanid":"Laskutussopimus",
				"esname":"Anna alayrityksen nimi",
				"rsname":"Alayrityksen nimi vaaditaan!"
			},

            "aclientuser":{
				"umanagement": "Käyttäjien hallinta",
				"adduser": "Lisää käyttäjä",
                "edituser":"Muokkaa käyttäjää",
                "uinfo":"Käyttäjätiedot",
				"Name": "Nimi",
				"eName": "Lisää nimi",
				"rName": "Nimi on pakollinen tieto!",
				"address": "Osoite",
				"eAddress": "Syötä osoite",
				"rAddress": "Osoite on pakollinen tieto!",
				"econtactno": "Syötä puhelinnumero",
				"rcontactno": "Puhelinnumero on pakollinen tieto!",
				"icontactno": "Puhelinnumero ei kelpaa!",
				"email": "Sähköpostiosoite",
				"eemail": "Anna sähköpostiosoite",
				"remail": "Sähköpostiosoite on pakollinen tieto!",
				"iemail": "Sähköpostiosoite ei kelpaa!",
				"country": "Maa",
				"scountry": "Syötä maan nimi",
				"rcountry": "Valitse maa!",
				"status": "Tila",
				"active": "Aktiivinen",
				"accesspermission": "Käyttöoikeudet",
				"general": "Yleiset",
                "dashboards":"Kojelaudat",
                "subcompany": "Yritys",
				"ssubcompany": "Valitse yritys",
				"rsubcompany": "Yritystieto on pakollinen tieto!",
				"costcenter": "Kustannuspaikka",
				"scostcenter": "Valitse kustannuspaikka",
				"rcostcenter": "Kustannuspaikka on pakollinen tieto!",
				"uploadinvoice": "Laskujen lataaminen",
                "clientmanagement":"Asiakkaat",
				"reports": "Raportit",
				"costsummary": "Optimoitavat kokonaiskustannukset",
				"usagesummary": "Optimoitava kokonaiskäyttö",
				"plantype": "Liittymätyypit",
                "topuser": "Top käyttäjät",
                "OverChargedPlans": "Laskutusvirheet",
                "clientcontracts":"Sopimukset",
				"masters": "Palvelun hallinta",
                "cmasters": "Asiakkuuden hallinta",
				"invoiceuser": "Puhelinetukäyttäjien hallinta",
				"plandetails": "Liittymien hallinta",
				"contractdetails":"Operaattorisopimuksen tiedot",
				"optimization": "Optimointi",
				"optimizeall": "Kaikki (Katselu, Export, Uudelleenoptimointi ja Lisää optimoitaviin)",
				"optimizeview": "Tietojen tarkastelu (lukuoikeus + vienti tiedostoon)",
				"save": "Tallenna",
				"update": "Päivitä",
				"cancel": "Peruuta",
				"operator": "Operaattori",
				"currency": "Valuutta",
				"costCenter": "Kustannuspaikkar",
				"costGroup": "Kustannusryhmät",
				"costCategory": "Kustannuskategoria",
				"costElement": "Kustannuslaji",
				"plan": "Liittymien luonti ja hallinta",
				"planGroup": "Liittymäryhmien hallinta",
				"basePlanGroup": "Liittymätyyppiryhmät",
				"skippedPhoneNumber": "Optimoinnissa ohitettavat numerot",
				"clientBillingPlan": "Sopimuspohja",
				"caddsuccess": "Asiakas lisätty onnistuneesti!",
				"cupdatesuccess": "Käyttäjä poistettu onnistuneesti!",	
                "ConsultantBilling": "Konsultti laskutus",		
                "downloads": "Lataukset",
                "unusedMonthCount":"Käyttämättömät kuukaudet lasketaan",
                "costAndUsage":"Tervetuloa",
				"savings":"Säästöt"									
			},
            
            "clientcontract":{
				"cmanagement": "Sopimushallinta",
				"addContract":"Lisää uusi sopimus",
				"tcontract": "Päätä sopimus",
				"terminatemsg":"Haluatko varmasti terminoida tämän sopimuksen?",	
				"tdate":"Sopimuksen päättämispäivä",
				"rtdate":"Sopimuksen päättämispäivä on pakollinen tieto!",
                "viewcontract":"Tarkastele sopimusta",
				"editcontract":"Muokkaa sopimusta",
				"terminatecontract":"Päätä sopimus",
                "terminationdateval":"Termination date should be greater than contract start date and less than contract end date!"
			},

			"viewclientcontract":{
				"vcontract": "Tarkastele sopimusta",
			},

            "billingmanagement":{
				"billingmanagement":"Laskutuksen hallinta",
				"accdetails":"Tilin",
				"cdetails":"Sopimustiedot",
				"sdate":"Sopimuksen alkamispäivä",
				"edate":"Sopimuksen päättymispäivä",
				"billsummary":"Laskutuksen yhteenveto",
				"ctype":"Maksulaji",
				"amt":"Summa (€)",
				"otcharges":"Kertamaksut",
				"rcharges":"Toistuvaismaksut (€)",
				"total":"Yhteensä (€)",
				"bdetails":"Laskutuksen tiedot",
				"bperiod":"Laskutusjakso",
				"units":"Kappalemäärä",
				"cpunits":"Kustannus €/kpl",
				"cost":"Kustannus",
				"mcost":"Kuukausimaksut",
				"ssufee":"Palvelun perustamismaksu (€)",
				"ssufpcompany":"Yrityskohtainen perustamismaksu(€)",
				"bafee":"Pääkäyttäjä BidGate (€/kk)",
				"nofplans":"Liittymämäärä",
				"nofaddusers":"Lisäkäyttäjien määrä",
				"autoiupload": "Automatisoitu laskun haku (€/kk)",
				"ondsupport":"Tukipalvelut",
				"advpaydisc":"Maksutapa-alennus %"
			},

			"addclientcontract": {
				"addcontract": "Lisää uusi sopimus",
				"editcontract": "Muokkaa sopimusta",
				"cterm":"Sopimuskausi",
				"scterm":"Valitse sopimuskauden pituus",
				"rcterm":"Sopimuskausi on pakollinen tieto!",
				"sdate":"Aloitus pvm.",
				"rstartdate":"Aloitus pvm. on pakollinen tieto!",
				"minstartdateval":"Aloitus pvm. tulee olla edellisen sopimuksen päättymisen jälkeen.!",
				"edate":"Päättymispäivä",
				"redate":"End date is Required",
				"inactivedate":"Passivointi pvm.",
				"bcycle": "Laskutussykli",
				"sbcycle": "Valitse laskutusjakso",
				"rbcycle": "Laskutussykli on pakollinen tieto!",
				"cbillingPlan": "Sopimuspohja",
				"scbillingPlan": "Valitse sopimuspohja",
				"rcbillingPlan": "Sopimuspohja on pakollinen tieto!",
				"nofplans": "Liittymämäärä",
				"snofplans": "Valitse liittymien lukumäärä",
				"units": "Kappalemäärä",
				"cpunit": "Kustannus €/kpl",
				"mcost": "Kuukausimaksut",
				"ycost": "Vuosikustannus (€)",
				"eunit": "Anna kappalemäärä",
				"total":"Yhteensä (€)",	
				"sadraft":"Tallenna luonnoksena",
				"unitval": "Yksikön tulee olla 0 tai 1.",
				"enoplanspu":"Syötä liittymäkohtainen hinta (€/kpl/kk)",
				"rnoplanspu":"Kustannus/liittymämäärä on pakollinen tieto!",
				"ecpadvancediscount":"Syötä maksutapa-alennus %",
				"rcpadvancediscount":"Alennusprosentti on pakollinen tieto!",
                "costperplans":"Liittymäkohtainen kustannus (€/kk/kpl)",
                "CompanyAdditionalUser":"Asiakkaan lisäkäyttäjät",
				"pCompanyAdditionalUser":"Syötä kustannus Asiakkaan lisäkäyttäjälle €/kk/käyttäjä",
			},
            
            "contractdetails": {
				"contractdetails": "Operaattorisopimuksen tiedot",
				"scpname": "Operaattorin yhteyshenkilön nimi",
				"escpname": "Anna operaattoriyhteyshenkilön nimi",
				"rscpname": "Operaattorin yhteyshenkilön nimi on pakollinen tieto!",
				"uireminder": "Muistutus: Lataa laskut",
				"ruireminder": "Muistutus: Lataa laskut on pakollinen tieto!",
				"ocsdate": "Operaattorisopimuksen alkamispäivä",
				"rocsdate": "Operaattorisopimuksen alkamispäivä on pakollinen tieto!",
				"ocedate": "Operaattorisopimuksen päättymispäivä",
				"rocedate": "Operaattorisopimuksen päättymispäivä on pakollinen tieto!",
				"minvdate": "Päivämäärän tulee olla Operaattorisopimuksen alkamispäivämäärän jälkeen!",
				"maxvdate": "Päivämäärän tulisi olla pienempi kuin operaattorisopimuksen päättymispäivä!",
				"cedreminder": "Muistutus: Sopimuksen päättymispäivä",
				"rcedreminder": "Muistutus: Sopimuksen päättymispäivä on pakollinen tieto!",
				"undocument": "Lataa dokumentti",
				"acdetails": "Lisää operaattorisopimuksen tiedot",
				"ecdetails": "Muokkaa sopimustietoja",
                "uploadnewdocument":"Lataa uusi dokumentti",
				"dname": "Dokumentin nimi",
				"edname": "Syötä dokumentin nimi",
				"rdname": "Dokumentin nimi on pakollinen tieto!",
				"upload": "Lataa",
				"udate": "Lataus pvm.",
				"idocument": "Virheellinen tiedoston muoto!",
				"sfileformat": "Sallitut tiedostotyypit: .doc, .docx, .xls, .xlsx, .csv, .pdf.",
				"docnameval": "Dokumentin nimi ei saa sisältää erikoismerkkejä.",
				"sfile": "Valitse tiedostot",
			},

			"clientpayment": {
				"payment":"Maksut",
				"paymentdate": "Maksupäivä",
				"description": "Kuvaus",
				"edescription": "Lisää kuvaus",
				"amount": "Summa (€)",
				"eamount": "Anna summa (€)",
				"add": "Lisää maksu",
				"rpaymentdate": "Maksupäivä on pakollinen tieto!",
				"mpaymentdate":"Maksupäivä ei voi olla tulevaisuudessa!",
				"rdescription": "Kuvaus on pakollinen tieto!",
				"ramount": "Summa on pakollinen tieto!",
			},

			"clientOnDemandSupport": {
				"ondemandsupport":"Tukipalvelut",
				"addondemandsupport":"Lisää käyttötukityötä",
				"onDemandSupportdate": "Tukipalvelun pvm.",
				"description": "Kuvaus",
				"edescription": "Lisää kuvaus",
				"hours": "Kesto (h)",
				"ehours": "Syötä tunnit",
				"ronDemandSupportdate": "OnDemand Support Date is required!",
				"dateval":"Tukipalvelun päivämäärä pitää olla tässä kuussa ja ei tämän päivän jälkeen!",
				"rdescription": "Kuvaus on pakollinen tieto!",
				"rhours": "Tuntimäärä on pakollinen tieto",
			}        
        }	
    }
};
