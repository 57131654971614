// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  isMockEnabled: false,
  clientDetails: {
    "token": "",
    "token_type": "",  
    "fullName": "",  
    "emailID": "",    
    "roleID": 0,        
    "languageID": 0,    
    "logo": "",    
    "applicationThemeID": 0,  
    "basePath": "",    
    "acessPermissions": [],
    "loggedInOperatorName":"",
    "loggedInOperatorID":0
  },

  // Local Site
  url: 'https://localhost:7184/',
  baseUrl: 'https://localhost:7184/api/',
  tokenUrl: 'https://localhost:7184/Token'

  // QA Server Site  
  // url: 'http://dev-bidgate.ecotechservices.com/',
  // baseUrl: 'http://dev-bidgate.ecotechservices.com/api/api/',
  // tokenUrl: 'http://dev-bidgate.ecotechservices.com/api/Token'

  // UAT Server Site  
  //  url: 'https://bidgateqa.ecotechservices.com/',
  //  baseUrl: 'http://bidgateuat.ecotechservices.com/api/api/',
  //  tokenUrl: 'http://bidgateuat.ecotechservices.com/api/Token'  
};
