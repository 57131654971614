import { ConfigModel } from '../core/interfaces/config';

export class MenuCompanyConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {			
			aside: {
				self: {},
				items: [				
					{
						title: 'Päänäkymä',
						bullet: "dot",												
						icon: 'flaticon-line-graph',
						translate:"customer.cdashboard.dashboard",
						page: '/company/dashboard',
						root: true,
						submenu:[
							{
								title: 'Tervetuloa',	
								translate: "customer.cdashboard.costAndUsage",
								page: '/company/dashboard/costAndUsage',
								root: true
												
							},
							{
								title: 'Säästöt',	
								translate: "customer.cDashboard_2.dashboard_2",
								page: '/company/dashboard/savings',
								root: true				
							},
						]
						
					},					
					{
						title: 'Lataa lasku',
						icon: 'flaticon-upload',
						translate:"customer.uinvoice.uinvoice",
						page: '/company/invoice-upload',						
						root: true
					},				
					{
						title: 'Raportit',
						bullet: "dot",
						icon: 'flaticon-interface-7',						
						translate: 'ckeywords.reports',	
						page: '/company/reports',					
						root: true,
						submenu: [
							{
								title: 'Optimoitavat kokonaiskustannukset',
								translate: 'customer.cseowise.csummaries',		
								page: '/company/report/cost-summaries',
								root: true			
							},
							{
								title: 'Optimoitava kokonaiskäyttö', 
								translate: 'ckeywords.usummaries',
								page: '/company/report/usage-summaries',
								root: true					
							},
							{
								title: 'Liittymätyypit',								
								translate: 'admin.plantypereport.plantype',
								page: '/company/report/base-plan-types',
								root: true					
							},
							{
								title: 'Top käyttäjät',
								translate: 'customer.topusersreport.tusers',								
								page: '/company/report/top-users',
								root: true					
							},
							{
								title: 'Laskutusvirheet',								
								translate: 'customer.overchargedplansreport.overchargedplans',
								page: '/company/report/overcharged-plans',
								root: true
							},
							{
								title: 'Downloads',								
								translate: 'admin.scheduleddownloads.downloads',
								page: '/company/report/downloads',
								root: true
							},
							{
								title: 'Unused Month Count',								
								translate: 'admin.unusedMonthCount.unusedMonthCountTitle',
								page: '/company/report/Unused-Month-Count',
								root: true
							}
						]
					},				
					{
						title: 'Hallintapaneeli',
						bullet: "dot",
						icon: "flaticon-layers", 
						translate: 'ckeywords.masters',	
						page: '/company/client-masters',
						root: true,
						submenu: [
							{
								title: 'Käyttäjähallinta',
								translate: "customer.umanagement.umanagement",							
								page: '/company/invoice-user-management'
							},
							{
								title: 'Matkapuhelinliittymien hallinta',								
								translate: "customer.pdetails.pdetails",
								page: '/company/plan-details'
							},
						]
					},
					{
						title: 'Optimointi',						
						icon: 'flaticon-graphic-2',
						translate: "customer.ostatistics.optimization",
						page: '/company/invoice-optimization',
						root: true
					}
				]
			}
		}
	}
}
